<template>
  <div>
    <div class="content-wrapper px-3">
      <section class="content">
        <section class="content-header p-1 px-2">
          <div class="div-header container-fluid">
              <div style="border-bottom: 1px solid #0001" class="row mb-2">
                <div class="col-sm-12 d-flex justify-content-between align-items-center">
                  <!-- <span></span> -->
                  <button class="btn btn-sm btn-outline-secondary" @click.prevent="$router.go(-1)">
                    <i class="fa fa-arrow-left mx-1"></i>Voltar
                  </button>
                  <h4 style="color: #0007" class="mt-3 ">
                    <i class="fa fa-edit"></i>
                    Editar Informações Financeiras da {{ companie_name.substring(0, 25) }}
                  </h4>
                  <button
                      :disabled="processing" 
                      data-bs-toggle="modal" data-bs-target="#modalConfirm" data-bs-whatever="@fat"
                      :class="status_client ? 'btn btn-danger' : 'btn btn-primary'" 
                      title="Congelar assinatura">
                    {{processing ? '' : (status_client ? 'Desativar assinatura' : 'Reativar assinatura')}}
                    <div v-show="processing" class="spinner-border search-admin" role="status" style="height: 1rem; width: 1rem;" />
                  </button>
                </div>
              </div>
            </div>
        </section>
        <div v-show="modalConfirm" class="modal fade" id="modalConfirm" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Confirmação</h5>
                </div>
                <div class="modal-body text-center" style="line-break: anywhere;">
                  Deseja realizar a {{status_client ? 'Desativação' : 'Ativação'}} da {{companie_name}}?
                </div>
                <div class="modal-footer">
                  <button type="button" @click="changeAccountStatus()" :class="status_client ? 'btn btn-danger' : 'btn btn-primary'" data-bs-dismiss="modal"><b>{{status_client ? 'Desativar' : 'Ativar'}}</b></button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><b>Fechar</b></button>
                </div>
              </div>
            </div>
        </div>
        <section class="content">
        <div>
            <div class="table-head">
              <label class="" >Definir responsáveis</label>
              <input type="text" class="input-search-employees" v-model="searchEmployees" @keyup="getFilterEmployees()"
                  placeholder="Pesquisar por email, nome, tipo" >
              <i id="sear-employee-glass" class="fa fa-search"  @click="getFilterEmployees()"></i>
            </div>
        </div>
        <div class="card-body table-responsive p-0" style="max-height: 70vh;">
          <table class="table table-hover table-stripped table-head-fixed">
            <thead>
                <tr>
                    <th>Nome</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(employee, i) in employees" :key="i" >
                    <td>{{employee.name}}</td>
                    <td>
                      <button
                          :disabled="processingOfficer" 
                          @click="changeFinancialOfficer(employee._id, employee.financial_officer, i)"
                          :class="employee.financial_officer ? 'btn btn-danger' : 'btn btn-primary'" 
                          :title="employee.financial_officer ? 'Desabilitar Acesso' : 'Habilitar Acesso'">
                        {{processingOfficer ? '' : (employee.financial_officer ? 'Desabilitar' : 'Habilitar')}}
                        <div v-show="processingOfficer" class="spinner-border search-admin" role="status" style="height: 1rem; width: 1rem;" />
                      </button>
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <hr />
        </section>
      </section>
    </div>
  </div>
</template>

<script>

import moment from 'moment-timezone'
import "moment/locale/pt-br.js"

export default {
  data() {
    return {
      company: [],
      status_client: true,
      companie_name: '',
      processing: false,
      processingOfficer: false,
      modalConfirm: false,
      employees: [],
      searchEmployees: ''
    }
  },
  methods: {
    // filtro nos funcionarios 
    getFilterEmployees() {

    let self = this

    if (self.searchEmployees == '') {
        self.employees = self.employeesGroup
    } else {
        self.employees = self.employeesGroup.filter(employee => {
            return ( employee.name.match(new RegExp(self.searchEmployees, "i"))
                || employee.email.match(new RegExp(self.searchEmployees, "i"))
                || employee.role.match(new RegExp(self.searchEmployees, "i"))
            )
        })
      }
    },

    // Busca os funcionarios
    getEmployees() {
    let self = this
    self.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${self.$store.getters.tokenJWT}`
        
    this.$tallos_admin_api.get(`employees/${self.$route.params.company_id}`)
        .then(res => {
            self.employees = res.data
            self.employeesGroup = res.data
        })
        .catch(e => {
            this.$toasted.global.defaultError({msg: 'Erro ao buscar empregados.'})
        })
    },

     //Altera o status do responsavel financeiro
    changeFinancialOfficer(id, status, index) {
      const new_status = !status;
      this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
        `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api
        .put('finance/change-financial-officer', {id, new_status})
        .then(res => {
          this.employees[index].financial_officer = !status;
          this.$toasted.global.defaultSuccess({msg: 'Alteração realizada com sucesso.'});
        })
        .catch(e => {
          this.$toasted.global.defaultError({msg: 'Não foi possivel realizar a operação.'})
          this.$router.go(-1);
        })
    },

    //Busca a empresa
    getCompany() {
      this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
        `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api
        .get(`companies/${this.$route.params.company_id}`)
        .then(res => {
          if (res.data.company) {
            this.company = res.data.company
            console.log(res.data.company)
            this.status_client = res.data.company.finance_data.status_client
            this.companie_name = res.data.company.razao_social
            this.$toasted.global.defaultSuccess({msg: 'Dados financeiros encontrados.'})
          }
        })
        .catch(e => {
          this.$toasted.global.defaultError({msg: 'Empresa não encontrada.'})
          this.$router.push({ path: '/admin/companies' })
        })
    },

    //Altera o status da assinatura da empresa
    changeAccountStatus() {
      this.processing = true;
      let data = {
        id_company: this.$route.params.company_id,
        id_client: this.company.finance_data.id_sacado_sac
      };

      if (this.status_client) {
        data['date'] = moment().format('MM/DD/YYYY');
      }

      this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
        `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api
        .post(`finance/change-account-status`, data)
        .then(res => {
          this.processing = false;
          this.status_client = !this.status_client;
          this.$toasted.global.defaultSuccess({msg: 'Empresa alterada com sucesso.'})
        })
        .catch(e => {
          this.processing = false;
          this.$toasted.global.defaultError({msg: 'Não foi possivel alterar o status da empresa.'})
        })
    }
  },

  mounted() {
    setTimeout(() => {
      this.getCompany();
      this.getEmployees();
    }, 500)
    document.title = 'Tallos Admin - Financeiro'
  }
}
</script>

<style scoped>
.content-wrapper {
  background-color: #fff;
}

.div-employees {
    background-color: rgba(247, 247, 247, 0.0);
}

table {
    border: 0.1px solid #0001;
}

table thead th {
    top: -3px !important; 
}

.table-head {
    width: 95%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
}

#sear-employee-glass {
    position: absolute;
    right: 10px;
    top: 11px;
    color: #0005;
    cursor: pointer;
}

.input-search-employees {
    border: 1px solid #0002;
    padding: 5px 10px;
    border-radius: 5px;
    width: 30%;
    font-size: 16px
}

.card-body {
    width: 95%;
    max-height: 60vh;
    display: flex;
    justify-content: center;
    margin: 0px auto;
    padding: 0px 10px;
    border: 1px solid #0001;
}
</style>